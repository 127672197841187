import { KupaIcon, HibajelentesIkon } from '../utils/Ikonok';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../pages/userkezeles/AuthContext';
import { Badge, Nav, Navbar, Container, NavDropdown, Button } from 'react-bootstrap';
import '../styles/navbar.css'; // Import the CSS file
import { SearchIkon } from '../utils/Ikonok';
import { usePermissions } from '../pages/userkezeles/PermissionContext';

function Iranyitopult() {
    const { user, logoutUser } = useContext(AuthContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { analogiaKeresoJog } = usePermissions();

    const handleDropdownToggle = () => setDropdownOpen(!dropdownOpen);
    const handleMouseEnter = () => setDropdownOpen(true);
    const handleMouseLeave = () => setDropdownOpen(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('#navbarScrollingDropdown')) {
                setDropdownOpen(false);
            }
        };

        if (dropdownOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownOpen]);

    return (
        <div className='animated-background-navbar rounded'>
            <Navbar expand="lg">
                <Container fluid>
                    <Navbar.Brand href="/">
                        <div className='mx-5 text-indigo'>Asztrováros</div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <div className="mx-auto">
                            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
                                <NavDropdown
                                    title={<div className='text-indigo'>Analógiák</div>}
                                    id="navbarScrollingDropdown"
                                    className={`mx-1 text-center ${dropdownOpen ? 'show' : ''}`}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={handleDropdownToggle}
                                    show={dropdownOpen}
                                >
                                    <NavDropdown.Item href="/gyakorloopciok" className="text-indigo"><KupaIcon size={18}/> Analógiagyakorló</NavDropdown.Item>
                                    <NavDropdown.Item href="/hibajelentestargyalo" className="text-indigo"><HibajelentesIkon  size={18}/> Hibajelentés tárgyaló</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/bolygok" className="text-indigo">Bolygók</NavDropdown.Item>
                                    <NavDropdown.Item href="/jegyek" className="text-indigo">Jegyek</NavDropdown.Item>
                                    <NavDropdown.Item href="/hazak" className="text-indigo">Házak</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/bolygokJegyekben" className="text-indigo">Bolygók Jegyekben</NavDropdown.Item>
                                    <NavDropdown.Item href="/bolygokHazakban" className="text-indigo">Bolygók Házakban</NavDropdown.Item>
                                    <NavDropdown.Item href="/hazakJegyekben" className="text-indigo">Házak Jegyekben</NavDropdown.Item>
                                    <NavDropdown.Item href="/hazakUraHazakban" className="text-indigo">Házak ura házakban</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    { user &&
                                        <NavDropdown.Item
                                            href="/analogiakereso"
                                            className={`${!analogiaKeresoJog ? "disabled text-muted" : "text-indigo"}`}
                                            disabled={!analogiaKeresoJog}
                                        >
                                            <SearchIkon size={18} className="mx-2" fillcolor={`${analogiaKeresoJog ? "gray" : "currentcolor"}`} /> Analógia kereső
                                        </NavDropdown.Item>
                                    }
                                </NavDropdown>
                                { user &&
                                <>
                                    <Nav.Link href="/kozosseg" className="mx-1 text-center text-indigo">Közösség</Nav.Link>
                                    <Nav.Link href="/helyezettek" className="mx-1 text-center text-indigo">Helyezettek</Nav.Link>
                                </>
                                }
                                <Nav.Link href="/tanulokozpont" className="mx-1 text-center text-indigo">Tanulóközpont</Nav.Link>
                                <Nav.Link href="/profil" className="mx-1 text-center text-indigo">
                                    {user && (
                                        <div className="text-indigo">
                                            {user.username}
                                            <br />
                                            <Badge variant="primary">{user.felhasznaloi_szint}</Badge>
                                        </div>
                                    )}
                                </Nav.Link>
                                <Nav.Link className="mx-1 text-center text-indigo">
                                    {user ? (
                                        <Button onClick={logoutUser} className="text-indigo btn-secondary">Kilépés</Button>
                                    ) : (
                                        <Link to="/login" className="text-indigo btn btn-success">Belépés</Link>
                                    )}
                                </Nav.Link>
                                <Nav.Link className="mx-1 text-center text-indigo">
                                    {!user && <Link to="/register" className="text-indigo btn btn-warning">Regisztráció</Link>}
                                </Nav.Link>
                            </Nav>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default Iranyitopult;
